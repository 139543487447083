import React from 'react';
import { motion } from 'framer-motion';
import { Link as RouterLink } from 'gatsby';
// material
import { styled } from '@mui/material/styles';
import { Box, Button, Typography, Container } from '@mui/material';
// components
import { MotionContainer, varBounceIn } from 'components/UIkit/animate';
import Page from 'components/UIkit/Page';
import logo from 'assets/img/icons/logo.png'

import Layout from "layout"
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(10),
  paddingBottom: theme.spacing(10)
}));

// ----------------------------------------------------------------------

export default function () {
  return (
    <Layout>
    <RootStyle title="404 | 楽単アルプス">
      <Container>
        <MotionContainer initial="initial" open>
          <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
            <motion.div variants={varBounceIn}>
              <Typography variant="h3" paragraph>
                404
              </Typography>
            </motion.div>
            <Typography sx={{ color: 'text.secondary' }}>
              お探しのページは見つかりませんでした
            </Typography>

            <motion.div variants={varBounceIn}>
              <Box
                component="img"
                src={logo}
                sx={{ width: 130, mx: 'auto', my: { xs: 5, sm: 10 } }}
              />
            </motion.div>

            <Button to="/" size="large" variant="contained" component={RouterLink} sx={{color: "#fff"}} >
              ホームへ戻る
            </Button>
          </Box>
        </MotionContainer>
      </Container>
    </RootStyle>
    </Layout>
  );
}